<template>
  <div>
    <!-- <div>
      <loading :active.sync="isLoader" loader="spinner" color="#36304a" :width="70"></loading>
    </div>-->

    <div class="animated fadeIn">
      <div id="snackbar"></div>
      <b-card-header class="mb-3">
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>Accounts Report
          </b-col>
        </b-row>
      </b-card-header>
      <b-card>
        <div class="row">
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">From Date :</label>
              <b-form-input type="date" v-model="accountsData.FromDate"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group>
              <label for="to-date">To Date :</label>
              <b-form-input type="date" v-model="accountsData.ToDate"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">Select Cinema Name</label>
              <b-form-select
                v-model="accountsData.selectedCinema"
                id="cinemas"
                :plain="true"
                text-field="CinemaName"
                value-field="CinemaID"
                :options="$store.getters['common/getAllCinemas']"
                @change="getMovie"
              >
                <!-- @change="getMovieByCinemaID" -->
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Cinema --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="movie_name">Movie :</label>
              <b-form-select
                required
                v-model="accountsData.selectedMovie"
                id="movie_name"
                :plain="true"
                text-field="Title"
                value-field="ID"
                :options="MovieList"
              >
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Movie --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <!-- <b-col sm="3">
          <b-form-group>
            <label for="from-date">Select Payment Mode </label>
            <b-form-select
              required
              v-model="accountsData.selectedMovie"
              id="cinemas"
              :plain="true"
              text-field="Movie"
              value-field="MovieID"
              :options="paymentList"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Payment Mode --</option>
              </template>
            </b-form-select>
          </b-form-group>
          </b-col>-->
        </div>
        <div class="row">
          <b-col sm="12" class="text-center">
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click="getDetailsAccountReport"
            >Search</b-button>
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click.prevent="resetForm"
            >Reset</b-button>
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click="ExportAccountExcel"
            >Export to Excel</b-button>
          </b-col>
        </div>
      </b-card>
      <hr />
      <b-card>
        <b-card>
          <div
            style="width: 100%;height: 100%;position: absolute;background: white;z-index: 1000;"
            v-if="isLoader"
          >
            <CircleLoader />
          </div>
          <v-client-table :data="data1" :columns="columns1" :options="options1"></v-client-table>

          <b-row>
            <p class="mx-3">
              Base Price + Vat on Base Tkt-(Bank Charges 2%+10% of VAT on bank
              charges)
            </p>
          </b-row>
          <hr />
          <!-- <v-client-table
          class="my-2"
          :data="data2"
          :columns="columns2"
          :options="options2"
          ></v-client-table>-->
          <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table
              class="table table-striped table-bordered"
              style="overflow-x: auto; width: 200px; height: 100px"
            >
              <thead style="color: white">
                <tr>
                  <th v-for="(head, index) of columnhead" :key="index">{{ head }}</th>
                </tr>
              </thead>
              <tbody v-if="displayedRecords.length > 0">
                <tr v-for="(record, index) of displayedRecords" :key="index">
                  <td>{{ record.Sr }}</td>
                  <td>{{ record.CinemaName }}{{ getUpdate }}</td>
                  <td>{{ record.MovieName }}</td>
                  <td>{{ record.TransactionDate }}</td>
                  <td>{{ record.ShowDate }}</td>
                  <td>{{ record.TransactionCount }}</td>
                  <td>{{ record.NoofTicket }}</td>
                  <td>{{ record.PaidAmount }}</td>
                  <td>{{ record.SCBConveniencefee }}</td>
                  <td>{{ record.VATONSCBConveniencefee }}</td>
                  <td>{{ record.TotalAmount }}</td>
                  <td>{{ record.PaidToPG }}</td>
                </tr>
                <tr>
                  <td colspan="5">
                    <b>Total</b>
                  </td>
                  <td>{{ TotalTransactionCount }}</td>
                  <td>{{ TotalNoofTicket }}</td>
                  <td>{{ TotalPaidAmount.toFixed(3) }}</td>
                  <td>{{ TotalSCBConveniencefee.toFixed(3) }}</td>
                  <td>{{ TotalVATONSCBConveniencefee.toFixed(3) }}</td>
                  <td>{{ TotalTotalAmount.toFixed(3) }}</td>
                  <td>{{ TotalPaidToPG.toFixed(3) }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="12">
                    <center>Data Not Found!</center>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :records="records.length"
            v-model="page"
            :per-page="perPage"
            @paginate="callback"
          ></pagination>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import AccountReportService from "@/services/AccountReportService";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName, WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";
import Pagination from "vue-pagination-2";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import CircleLoader from "../../SchedulesDetails/Circle.vue";

import ReportsService from "@/services/ReportsService";

export default {
  name: "AccountsReport",
  components: {
    Event,
    cSwitch,
    Pagination,
    // Loading,
    CircleLoader
  },
  watch: {},
  data: function() {
    return {
      isLoader: false,
      TotalTransactionCount: 0,
      TotalNoofTicket: 0,
      TotalPaidAmount: 0,
      TotalSCBConveniencefee: 0,
      TotalVATONSCBConveniencefee: 0,
      TotalTotalAmount: 0,
      TotalPaidToPG: 0,
      page: 1,
      perPage: 10,
      records: [],
      counter: 21,
      columnhead: [
        "SN.",
        "Cinema Name",
        "Movie Name",
        "Transaction Date",
        "Movie Date",
        "Total Transaction",
        "No. of Tickets",
        "Paid Amount",
        "SCB Convenience Fee",
        "VAT(10%) on SCB Convenience Fee",
        "Paid To Cineco",
        "Paid To Payment GateWay"
      ],

      transactionDateReport: [],
      accountsData: {
        FromDate: "",
        ToDate: "",
        selectedCinema: "0",
        selectedMovie: "0"
      },
      paymentList: ["All", "Debit Card", "Credit Card", "SCB"],
      cinemaList: [],
      MovieList: [],
      summaryData: [],

      columns1: [
        "total_amount",
        "SCBTickets",
        "NormalTickets",
        "total_tickets",
        "CinecoAmount",
        "PGAmount"
      ],
      data1: [],
      options1: {
        headings: {
          total_amount: "Total Amount",
          SCBTickets: "SCB Tickets",
          NormalTickets: "Normal Tickets",
          total_tickets: "Total Tickets",
          CinecoAmount: "Cineco Amount(BHD)",
          PGAmount: "Payment Gateway Amount(BHD)"
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      //Book New Transcation
      columns2: [
        "Sr",
        "CinemaName",
        "MovieName",
        "TransactionDate",
        "ShowDate",
        "TransactionCount",
        "NoofTicket",
        "PaidAmount",
        "SCBConveniencefee",
        "VATONSCBConveniencefee",
        "TotalAmount",
        "PaidToPG"
      ],
      finalArr: [],
      ExcelArr: [],
      data2: [],
      options2: {
        headings: {
          Sr: "SN.",
          CinemaName: "Cinema Name",
          MovieName: "Movie Name",
          TransactionDate: "Transaction Date",
          ShowDate: "Movie Date",
          TransactionCount: "Total Transaction",
          NoofTicket: "No. of Tickets",
          PaidAmount: "Paid Amount",
          SCBConveniencefee: "SCB Convenience Fee",
          VATONSCBConveniencefee: "VAT(10%) on SCB Convenience Fee",
          TotalAmount: "Paid To Cineco",
          PaidToPG: "PaidToPaymentGateWay"
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },

      //Book New Transcation

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  beforeMount() {
    let payload = {};
    // alert(this.secureStore.get("TTURO"));
    // alert(this.secureStore.get("TTURO"));
    if (
      this.secureStore.get("TTURO") == "Admin" ||
      this.secureStore.get("TTURO") == "Finance"
    ) {
      payload = {
        OrgID: 3
      };
    }
    this.$store.dispatch("common/setAllCinemas", payload);
    // this.$store.dispatch("common/setAllCinemas");
    this.getFromToDate();
    this.getDetailsAccountReport();
  },
  mounted() {},
  methods: {
    getMovie() {
      if (this.accountsData.selectedCinema) {
        let payload = {
          CinemaID: this.accountsData.selectedCinema
        };
        this.MovieList = [];
        AccountReportService.getMoviesByScreenID(payload)
          .then(response => {
            const { data } = response;
            if (data.MovieList.length > 0) {
              this.MovieList = data.MovieList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch(error => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    callback: function(page) {
      this.TotalTransactionCount = 0;
      this.TotalNoofTicket = 0;
      this.TotalPaidAmount = 0;
      this.TotalSCBConveniencefee = 0;
      this.TotalVATONSCBConveniencefee = 0;
      this.TotalTotalAmount = 0;
      this.TotalPaidToPG = 0;
    },
    getMovieByCinemaID() {
      if (this.accountsData.selectedCinema) {
        let payload = {
          screenID: this.accountsData.selectedCinema
        };
        this.movieList = [];
        AccountReportService.getMovieByCinemaID(payload)
          .then(response => {
            const { data } = response;
            if (
              data.Status &&
              (data.movieList.length > 0 || data.movieList.length > 0)
            ) {
              this.movieList = data.movieList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch(error => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    getFromToDate() {
      var today = new Date();
      var firstDay = "";
      var lastDay = "";
      // firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      var firstDay_dd = String(firstDay.getDate()).padStart(2, "0");
      var firstDay_mm = String(firstDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var firstDay_yyyy = firstDay.getFullYear();

      var lastDay_dd = String(lastDay.getDate()).padStart(2, "0");
      var lastDay_mm = String(lastDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var lastDay_yyyy = lastDay.getFullYear();

      firstDay = firstDay_yyyy + "-" + firstDay_mm + "-" + firstDay_dd; //2018-06-01
      lastDay = lastDay_yyyy + "-" + lastDay_mm + "-" + lastDay_dd; //2018-06-01

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "-" + mm + "-" + yyyy; //2018-06-01
      this.FromDate = today;
      this.accountsData.FromDate = firstDay;
      this.accountsData.ToDate = firstDay;
      // this.accountsData.ToDate = lastDay;
    },
    ExportAccountExcel() {
      if (this.ExcelArr.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.ExcelArr);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "Internal");
        let ExcelDate = new Date().toDateString();
        if (this.accountsData.FromDate) {
          ExcelDate = new Date(this.accountsData.FromDate).toDateString();
        } else {
          ExcelDate = new Date().toDateString();
        }
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_Cineco_Account_DETAILS_Report_${ExcelDate}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getDetailsAccountReport() {
      this.data1 = [];
      this.data2 = [];
      this.finalArr = [];
      this.ExcelArr = [];
      this.isLoader = true;
      let payload = {
        FromDate: this.accountsData.FromDate,
        ToDate: this.accountsData.ToDate,
        CinemaID: this.accountsData.selectedCinema,
        PaymentMode: this.accountsData.selectedPaymentMode,
        MovieID: this.accountsData.selectedMovie,
        Source: "cineco",
        organization_id: "3"
      };

      AccountReportService.getInternalAccountsReport(payload)
        .then(response => {
          const { data } = response;
          if (
            data.Status &&
            (data.InternalReportsDetails.length > 0 ||
              data.TotalDetails.length > 0)
          ) {
            this.data2 = data.InternalReportsDetails;
            this.data1 = data.TotalDetails;
            this.records = [];
            if (this.data2.length > 0) {
              this.data2.forEach(element => {
                this.records.push(element);
              });
              let finalArr = this.finalArr;
              let NewArr = this.data2.map(x => {
                finalArr.push({
                  "SN.": x["Sr"],
                  "Cinema Name": x["CinemaName"],
                  "Movie Name": x["MovieName"],
                  "Transaction Date": x["TransactionDate"],
                  "Movie Date": x["ShowDate"],
                  "Total Transaction": x["TransactionCount"],
                  "No. of Tickets": x["NoofTicket"],
                  "Paid Amount": x["PaidAmount"],
                  "SCB Convenience Fee": x["SCBConveniencefee"],
                  "VAT(10%) on SCB Convenience Fee":
                    x["VATONSCBConveniencefee"],
                  "Paid To Cineco": x["TotalAmount"],
                  "Paid to Payment Gateway": x["PaidToPG"]
                });
              });
              this.ExcelArr = finalArr;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
          this.isLoader = false;
        })
        .catch(error => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    resetForm() {
      Object.keys(this.accountsData).forEach(
        key => (this.accountsData[key] = "")
      );
      this.getFromToDate();
      this.accountsData["selectedCinema"] = "0";
      this.accountsData["selectedMovie"] = "0";
    },
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  },
  computed: {
    displayedRecords() {
      this.TotalTransactionCount = 0;
      this.TotalNoofTicket = 0;
      this.TotalPaidAmount = 0;
      this.TotalSCBConveniencefee = 0;
      this.TotalVATONSCBConveniencefee = 0;
      this.TotalTotalAmount = 0;
      this.TotalPaidToPG = 0;
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.records.slice(startIndex, endIndex);
      this.records.slice(startIndex, endIndex);
      var newArray = [];

      this.records.forEach(value => {
        var exists = false;
        newArray.forEach(val2 => {
          if (value.OrderID == val2.OrderID) {
            exists = true;
          }
        });
        if (exists == false && value.OrderID != "") {
          newArray.push(value);
        }
      });
      this.records = newArray;

      console.log("this.records=>", this.records);

      return newArray;
    },
    getUpdate() {
      // this.callback();
      this.displayedRecords.forEach(element => {
        this.TotalTransactionCount =
          parseInt(this.TotalTransactionCount) +
          parseInt(element.TransactionCount);
        this.TotalNoofTicket =
          parseInt(this.TotalNoofTicket) + parseInt(element.NoofTicket);
        this.TotalPaidAmount =
          parseFloat(this.TotalPaidAmount) + parseFloat(element.PaidAmount);
        this.TotalSCBConveniencefee =
          parseFloat(this.TotalSCBConveniencefee) +
          parseFloat(element.SCBConveniencefee);
        this.TotalVATONSCBConveniencefee =
          parseFloat(this.TotalVATONSCBConveniencefee) +
          parseFloat(element.VATONSCBConveniencefee);
        this.TotalTotalAmount =
          parseFloat(this.TotalTotalAmount) + parseFloat(element.TotalAmount);
        this.TotalPaidToPG =
          parseFloat(this.TotalPaidToPG) + parseFloat(element.PaidToPG);
      });
    }
  },
  created() {
    this.records = [];
    if (this.data2.length > 0) {
      this.data2.forEach(element => {
        this.records.push(element);
      });
    }
  }
};
</script>

<style>
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
.pagination-row {
  padding: 5px 0;
}

.my-custom-scrollbar {
  position: relative;
  height: 850px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
